<template>
  <div>
    <div class="bg max-w1200 mrauto mt47 mb51 pd20">
      <div class="bg-ffffff bor-E6E6E6">
        <!-- 发布 -->
        <div class="">
          <el-card class="box-card">
            <el-form label-width="10rem" label-position="right">
              <el-form-item label="推荐诚意金：">
                <div class="box-input">
                <el-input
                  v-model="form.earnest_money"
                  placeholder="请输入诚意金"
                  @change="numprice"
                ></el-input>
                </div>
                <div class="fs-14 col-D8252D">
                  <p>最低诚意金金额：￥{{ Info.min_bond }}</p>
                  <p>
                    推荐诚意金为会员分享佣金，广告在线期间，平台和店主均无法收佣提现，店主确认成功转让后平台收佣，转店失败{{
                      Info.x_pro
                    }}%诚意金退还店主。
                  </p>
                </div>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card class="box-card mt20">
            <div slot="header" class="clearfix">
              <span>付费推广</span>
            </div>
            <el-form ref="form" :model="form" label-width="10rem" label-position="right">
              <el-form-item label="选择广告位：">
                <el-select v-model="form.type" placeholder="广告位" @change="changeType">
                  <el-option label="头版" value="1"></el-option>
                  <el-option label="优选" value="2"></el-option>
                  <el-option label="无" value="10"></el-option>
                </el-select>
                <!-- <div class="fs-14 col-D8252D">
                <p>如若要免费发布请选择“无”</p>
              </div> -->
              </el-form-item>
              <el-form-item label="购买类型：" v-if="form.type != 10">
                <el-select
                  v-model="form.paytype"
                  placeholder="购买类型"
                  @change="changeType2"
                >
                  <el-option label="单独购买" value="1"></el-option>
                  <el-option label="推荐套餐" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="选择投放的天数："
                v-if="form.type != 10 && form.paytype == 1"
              >
                <div class="flex">
                  <div class="box-input">
                  <el-input
                    v-model="form.ad_day"
                    placeholder="请输入投放的天数"
                    @change="numprice"
                  ></el-input>
                  </div>
                  <div class="ml10 fs-14 col-D8252D" v-if="form.type == 1">
                    {{ Info.select_money }}元/天
                  </div>
                  <div class="ml10 fs-14 col-D8252D" v-if="form.type == 2">
                    {{ Info.good_money }}元/天
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                label="增加刷新次数："
                v-if="form.type != 10 && form.paytype == 1"
              >
                <div class="flex">
                  <el-input-number
                    v-model="form._mc"
                    :min="0"
                    label="描述文字"
                    @change="numprice"
                  ></el-input-number>
                  <div class="ml10 fs-14 col-D8252D">{{ Info.ref_money }}元/次</div>
                </div>
              </el-form-item>
              <el-form-item
                label="连续刷新天数："
                v-if="form.type != 10 && form.paytype == 1"
              >
                <el-input-number
                  v-model="form._lx"
                  :min="0"
                  label="描述文字"
                  @change="numprice"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                label="选择刷新时间段："
                v-if="form.type != 10 && form.paytype == 1"
              >
                <el-time-picker
                  is-range
                  v-model="form.time_frame"
                  value-format="HH:mm"
                  format="HH:mm"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                >
                </el-time-picker>
              </el-form-item>
              <el-form-item
                label="选择套餐："
                v-if="form.type != 10 && form.paytype == 2"
              >
                <div>
                  <ul class="flex a-center f-w">
                    <li
                      v-for="(item, index) in cardList"
                      :key="index"
                      class="l-h20 mr15 mb15 ptb4lr11 cup"
                      :class="form.cardid == item.id ? 'card_active' : 'card'"
                      @click="cardclick(item)"
                    >
                      <p>
                        {{ item.name }}
                      </p>
                      <p class="fs-14">会员价：{{ item.price }}</p>
                      <p class="fs-14 col-D8252D">优惠价：{{ item.all_money }}</p>
                    </li>
                  </ul>
                </div>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card class="box-card mt20">
            <div>
              <div
                class="flex a-center j-sb mb15"
                v-if="form.type != 10 && form.paytype == 1"
              >
                <div></div>
                <div class="flex a-center">
                  <div class="col-333333 mr15">刷新费用（不可退）：</div>
                  <div class="fs-18 fwb col-999999">￥{{ form.refreshprice }}</div>
                </div>
              </div>
              <div
                class="flex a-center j-sb mb15"
                v-if="form.type != 10 && form.paytype == 1"
              >
                <div></div>
                <div class="flex a-center">
                  <div class="col-333333 mr15">广告费（不可退）：</div>
                  <div class="fwb fs-18 col-999999">￥{{ form.select_moneyprice }}</div>
                </div>
              </div>
              <div class="flex a-center j-sb mb15">
                <div></div>
                <div class="flex a-center">
                  <div class="col-333333">诚意金（转让成功不可退）：</div>
                  <div class="fs-18 fwb col-999999">
                    ￥{{ form.earnest_money ? form.earnest_money : 0 }}
                  </div>
                </div>
              </div>
              <div class="flex a-center j-sb mb15">
                <div></div>
                <div class="flex a-center">
                  <div class="col-333333">小计：</div>
                  <div class="fs-18 fwb col-D8252D">￥{{ form.all_money }}</div>
                </div>
              </div>
            </div>
          </el-card>
          <div class="flex a-center j-sa pd40">
            <el-button @click="sbmitpay(3)">保存草稿</el-button>
            <el-button @click="sbmitpay(1)">免费发布</el-button>
            <el-button type="primary" @click="sbmitpay(2)">付费推广</el-button>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="centerDialogVisible" width="30%" center>
        <div class="h572" style="overflow-y: auto">
          <div v-html="content"></div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="centerDialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :visible.sync="payflag"
        width="30%"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="wh400 mrauto">
          <img :src="paycode" alt="" class="wh400" />
        </div>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="payclick(1)">取消支付</el-button>
          <el-button type="primary" @click="payclick(2)">已经支付</el-button>
        </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  select_ad_view,
  select_card,
  use_coupons,
  select_ad_sub,
  pc_pay,
  get_is_pay,
  save_drafts,
} from "../../utils/api.js";
export default {
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        this.s_type = this.$route.query.s_type;
        this.id = this.$route.query.id;
      },
    },
  },
  data() {
    return {
      timer:"",
      payflag: false,
      paycode: "",
      s_type: "",
      id: "",
      centerDialogVisible: false, //套餐内容控制
      content: "", //套餐内容
      form: {
        type: "1", //广告位类型
        paytype: "1", //购买类型
        earnest_money: "", //推荐诚意金
        ad_day: "", //投放天数
        _mc: "", //增加刷新次数
        _lx: "", //连续刷新天数
        time_frame: "", //刷新时段
        refreshprice: 0, //刷新费用
        select_moneyprice: 0, //广告总费用-头版
        good_moneyprice: 0, //广告总费用-优选
        all_money: 0, //总价
        cardmoney: 0, //套餐价格
        cardid: "", //套餐id
        use_couponsid: "", //优惠id
        use_couponsprice: "", //优惠价格
      },
      city: "", //城市
      city_area: "", //区域
      cardList: "",
      Info: "",
      order_code: "",
      uid: "",
    };
  },
  mounted() {
    let uid = window.localStorage.getItem("uid");
    if (uid) {
      this.uid = uid;
    } else {
      this.$message.error("还未登录，请登录后再使用");
      setTimeout(() => {
        this.$router.push({
          path: "/login",
          query: {navid: 10,},
        });
        // let routeData = this.$router.resolve({
        //   path: "/login",
        //   query: { navid: 10 },
        // });
        // window.open(routeData.href, "_blank");
      }, 1500);
    }
    this.s_type = this.$route.query.s_type;
    this.id = this.$route.query.id;
    this.getInfo();
    this.getselect_card();
  },
  methods: {
    cardclick(item) {
      console.log(item);
      this.centerDialogVisible = true;
      this.content = item.text
        .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
        .replace(/<section/g, "<div")
        .replace(/\/section>/g, "div>");
      this.form.cardmoney = item.all_money;
      this.form.cardid = item.id;
      this.form.ad_day = item.ad_days;
      this.form._mc = item.day_ref;
      this.form._lx = item.ref_days;
      this.form.time_frame = item.ref_between_time;
      this.numprice();
    },
    sbmitpay(id) {
      let that = this;
      if (id == 1) {
        select_ad_sub({
          uid: that.uid,
          id: that.id,
          type: that.s_type,
          bond: that.form.earnest_money,
          ad_loc: that.form.type,
          ad_days: that.form.ad_day,
          day_ref: that.form._mc,
          ref_days: that.form._lx,
          ref_between_time: that.form.time_frame[0] + "~" + that.form.time_frame[1],
          all_money: that.form.all_money,
          send_money: that.Info.send_money,
          card_id: that.form.cardid,
          is_pay: 0,
          city: window.localStorage.getItem("city"),
          dis_id: that.form.use_couponsid,
        })
          .then((res) => {
            console.log(res);
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            that.$message.success(res.msg);
            setTimeout(() => {
              that.$router.push({
                path: "/indexer",
                query: { navid: 1 },
              });
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
            that.$message.error(err.msg);
          });
      } else if (id == 2) {
        select_ad_sub({
          uid: that.uid,
          id: that.id,
          type: that.s_type,
          bond: that.form.earnest_money,
          ad_loc: that.form.type,
          ad_days: that.form.ad_day,
          day_ref: that.form._mc,
          ref_days: that.form._lx,
          ref_between_time: that.form.time_frame[0] + "~" + that.form.time_frame[1],
          all_money: that.form.all_money,
          send_money: that.Info.send_money,
          card_id: that.form.cardid,
          city: window.localStorage.getItem("city"),
          dis_id: that.form.use_couponsid,
        })
          .then((res) => {
            console.log(res);
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            that.pay(res.data.all_money, res.data.order_code, res.data.uid);
          })
          .catch((err) => {
            console.log(err);
            that.$message.error(err.msg);
          });
      } else {
        save_drafts({
          id: this.id,
          type: this.s_type,
          is_pay: "",
        })
          .then((res) => {
            console.log(res);
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            that.$message.success(res.msg);
            setTimeout(() => {
              that.$router.push({
                path: "/member",
                query: {},
              });
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
            that.$message.error(err.msg);
          });
      }
    },
    pay(all_money, order_code, uid) {
      let that = this;
      pc_pay({
        all_money: all_money,
        order_code: order_code,
        order_type: 1,
        uid: uid,
      })
        .then((res) => {
          console.log(res);
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.paycode = res.data;
          that.payflag = true;
          that.order_code = order_code;
          that.timer = window.setInterval(() => {
            setTimeout(() => {
              this.payclick(2);
            }, 0);
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    payclick(id) {
      let that = this;
      if (id == 1) {
        that.$message.error("您已取消支付");
        setTimeout(() => {
          that.$router.push({
            path: "/indexer",
            query: { navid: 1 },
          });
        }, 1500);
      } else {
        get_is_pay({
          order_code: that.order_code,
        })
          .then((res) => {
            console.log(res);
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            that.$message.success(res.msg);
             window.clearInterval(that.timer)
            setTimeout(() => {
              that.$router.push({
                path: "/member",
                query: {},
              });
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
            // that.$message.error(err.msg);
          });
      }
    },
    // 计算价格
    numprice() {
      console.log(this.form);
      if (this.form.paytype == 2) {
        this.form.all_money =
          Number(this.form.cardmoney) + Number(this.form.earnest_money);
        this.form.all_money = this.form.all_money.toFixed(2);
      } else {
        // 刷新总费用
        this.form.refreshprice =
          Number(this.Info.ref_money) * Number(this.form._mc) * Number(this.form._lx);
        this.form.refreshprice = this.form.refreshprice.toFixed(2);
        // 广告费总费用
        this.form.select_moneyprice =
          Number(this.Info.select_money) * Number(this.form.ad_day);
        this.form.select_moneyprice = this.form.select_moneyprice.toFixed(2);
        this.form.good_moneyprice =
          Number(this.Info.good_money) * Number(this.form.ad_day);
        this.form.good_moneyprice = this.form.good_moneyprice.toFixed(2);
        if (this.form.type == 1) {
          this.form.all_money =
            Number(this.form.refreshprice) +
            Number(this.form.select_moneyprice) +
            Number(this.form.earnest_money);
          this.form.all_money = this.form.all_money.toFixed(2);
        }
        if (this.form.type == 2) {
          this.form.all_money =
            Number(this.form.refreshprice) +
            Number(this.form.good_moneyprice) +
            Number(this.form.earnest_money);
          this.form.all_money = this.form.all_money.toFixed(2);
        }
      }
      if (this.form.type == 10) {
        if (this.form.earnest_money && this.form.earnest_money != 0) {
          this.form.all_money = this.form.earnest_money;
        } else {
          this.form.all_money = this.Info.send_money ? this.Info.send_money : 0;
        }
      }
      console.log("this.form.all_money", this.form.all_money);
      this.getuse_coupons();
    },
    getuse_coupons() {
      let that = this;
      let type = "";
      if (that.form.type != 10 && that.form.type) {
        type = that.form.type;
      } else {
        type = "";
      }
      use_coupons({
        bond: that.form.earnest_money,
        money: that.form.all_money,
        type: type,
        uid: that.uid,
      })
        .then((res) => {
          console.log(res);
          this.form.use_couponsid = res.data.id;
          this.form.all_money = res.data.money;
          this.form.use_couponsprice = res.data.dec_money;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getInfo() {
      let that = this;
      select_ad_view({
        city: window.localStorage.getItem("city"),
        city_area: "",
        uid: that.uid,
      })
        .then((res) => {
          console.log(res);
          that.Info = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getselect_card() {
      let that = this;
      select_card({
        type: that.form.type,
        city: window.localStorage.getItem("city"),
        city_area: "",
      })
        .then((res) => {
          console.log(res);
          that.cardList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeType(e) {
      this.form = {
        type: e,
        paytype: "1",
        earnest_money: "",
        ad_day: "", //投放天数
        _mc: "", //增加刷新次数
        _lx: "", //连续刷新天数
        time_frame: "", //刷新时段
        refreshprice: 0, //刷新费用
        select_moneyprice: 0, //广告总费用-头版
        good_moneyprice: 0, //广告总费用-优选
        all_money: 0, //总价
        cardmoney: 0, //套餐价格
        cardid: "", //套餐id
        use_couponsid: "", //优惠id
        use_couponsprice: "", //优惠价格
      };
      this.getselect_card();
      this.numprice();
    },
    changeType2(e) {
      this.form = {
        type: this.form.type,
        paytype: e,
        earnest_money: "", //推荐诚意金
        ad_day: "", //投放天数
        _mc: "", //增加刷新次数
        _lx: "", //连续刷新天数
        time_frame: "", //刷新时段
        refreshprice: 0, //刷新费用
        select_moneyprice: 0, //广告总费用-头版
        good_moneyprice: 0, //广告总费用-优选
        all_money: 0, //总价
        cardmoney: 0, //套餐价格
        cardid: "", //套餐id
        use_couponsid: "", //优惠id
        use_couponsprice: "", //优惠价格
      };
      this.numprice();
    },
  },
};
</script>

<style lang="scss">
// .bg {
//   background-image: url(../../assets/img/bg.png);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
// }
.card_active {
  color: #18b566;
  background-color: #dbf1e1;
  border: 0.0625rem solid #18b566;
}
.card {
  color: #333333;
  background-color: #f4f4f4;
  border: 0.0625rem solid #999999;
}
.box-input{
  .el-input .el-input__inner{
    width: auto !important;
  }
}
.el-input.is-disabled .el-input__inner {
 
  background-color: #ffffff !important;
  border-color: #d2d2d2 !important;
  color: #222222 !important;
  font-size: 0.875rem !important;
  cursor: not-allowed;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 0.375rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 1.75rem;
  color: #8c939d;
  width: 11.125rem;
  height: 11.125rem;
  line-height: 11.125rem;
  text-align: center;
}
.avatar {
  width: 11.125rem;
  height: 11.125rem;
  display: block;
}
p {
  margin: 0;
  padding: 0;
}
</style>
